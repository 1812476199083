import React, { Component } from 'react';
import { Upload, Breadcrumb, Modal, message } from 'antd';
import upload from '../../asset/image/upload@2x.png'

// 临时测试使用
import CPUpload from './../../Datas/Uplode/index'
import {GetUploadManager} from './../../Datas/Uplode/upload'
import CPAppManager from '../../Datas/app/index'
import { appAdapter } from '../../app/appadaper'

import AWS from 'aws-sdk'

import { hexMD5 } from '../../request/md5';
import streamToBlob from 'stream-to-blob'


import './index.scss'
const Dragger = Upload.Dragger;
export default class CPDragUpload extends React.Component {
    constructor() {
        super();
        this.user = new CPUpload();

        let _this = this
        this.layout = {
            name: 'file', // 发到后台的文件参数名
            multiple: false, // 是否支持多选文件，ie10+ 支持。开启后按住 ctrl 可选择多个文件
            action: '', // 上传地址
            accept: 'video/*',
            onChange(info) { // 上传文件改变时的状态
                console.log('上传文件的信息',info);
                const { status } = info.file;
                if (status !== 'uploading') {
                    console.log(info.file, info.fileList);
                    let size = info.file.size
                    let max = 4*1024*1024*1024
                    if(max <= size){
                        let obj = {
                            title: '上传视频过大',
                            content: '您上传的视频过大，请上传4GB以内的视频内容'
                        }
                        _this.errorModal(obj)
                        return
                    }
                    if(info.file.type !== "video/mp4"){
                        let obj = {
                            title: '上传视频格式错误',
                            content: '您上传的视频格式错误，请选择其他的视频格式进行上传'
                        }
                        _this.errorModal(obj)
                        return
                    }
                    
                    // GetUploadManager().pushUploadObject(info.file);

                    // let uploadManager =  GetUploadManager();

                    GetUploadManager().currentSelectUploadObject = info.file;


                    // console.log(window.mediaInfoHandler);
                    // GetUploadManager().calculateMD5Hash(info.file.originFileObj,10 * 1024 * 1024).then(res=>{

                    //     if (GetUploadManager().hasUplaodObject(res)) {
                    //         window.alert('当前文件存在');
                    //         return ;
                    //     }

                    //     // info.file.uid = res;
                    //     // GetUploadManager().pushUploadObject(info.file);
                    //     GetUploadManager().publishVideo(info.file.originFileObj, {});

                    //     console.log('当前文件的hash数据',res);
                    // }).catch(err=>{
                    //     console.log('计算hash出错',err);
                    // });
                    // _this.uploadFile(info.file.originFileObj);
                    _this.props.history.push({ pathname: '/uploadvideo', state: { name: 'sunny' } })
                }
                // if (status === 'done') {
                //     message.success(`${info.file.name} file uploaded successfully.`);
                // } else if (status === 'error') {
                //     message.error(`${info.file.name} file upload failed.`);
                // }
            },
        };


    }
    componentDidMount() {
        // console.log(this.props.location.state, '................')
        let wapp_user = appAdapter().getStore().getWappUser()
        // console.log('wapp_user  ', wapp_user.uploadFlag)
        let _this = this
        // 用户是否禁止上传 1 是 0 否
        if(wapp_user.uploadFlag) {
            Modal.error({
                content: '您已经被禁止上传',
                okText: '我知道了',
                onOk: function (){
                    _this.props.history.push({ pathname: '/UploadList', state: { name: 'sunny' } })
                }
            });
        }


        // this.errorModal();
        this.user.getUploadParams().then(res=>{

            window.accessKey = res.data[0].accessKey;
            window.secretKey = res.data[0].secretKey;
            window.token = res.data[0].token;
            window.userId = res.data[0].userId;
           
            console.log('获取上传的参数');
        }).catch(err=>{

        });
    }

    errorModal(obj) {
        // obj = {
        //     title: '上传视频格式错误',
        //     content: '您上传的视频格式错误，请选择其他的视频格式进行上传'
        // }
        // obj = {
        //     title: '上传视频过大',
        //     content: '您上传的视频过大，请上传4GB以内的视频内容'
        // }
        Modal.error({
            title: obj.title,
            content: obj.content,
            okText: '删除'
        });
      }



      
    render() {
        return (
            <div>
                <Breadcrumb style={{ margin: '0 0 15px' }}>
                    <Breadcrumb.Item>上传助手</Breadcrumb.Item>
                    <Breadcrumb.Item>上传视频</Breadcrumb.Item>
                </Breadcrumb>
                <div className="Dragger" >
                    <Dragger {...this.layout}>
                        <p className="ant-upload-drag-icon">
                            <img src={upload}></img>
                        </p>
                        <p className="ant-upload-text">点击上传或将视频文件拖拽到这里上传</p>
                    </Dragger>
                </div>
                <div className="DraggerBottom">
                    <div className="bottom-div">
                        <h3 className="bottom-div-title">视频格式</h3>
                        <p className="bottom-div-text">支持视频常用格式，推荐使用mp4</p>
                    </div>
                    <div className="bottom-div">
                        <h3 className="bottom-div-title">视频大小</h3>
                        <p className="bottom-div-text">视频文件大小不超过4G，时长不限</p>
                    </div>
                    <div className="bottom-div">
                        <h3 className="bottom-div-title">视频分辨率</h3>
                        <p className="bottom-div-text">视频分辨率为360p(480x360)以上</p>
                    </div>
                </div>
            </div>
        )
    }
}