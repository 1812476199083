
export function setStorageSync(key,value) {
    let str = typeof value !== 'string' ? JSON.stringify(value) : value
    sessionStorage.setItem(key, str)
}

export function getStorageSync(key) {
    let value = sessionStorage.getItem(key)
    if (isJSON(value)) return JSON.parse(value)
    return value
}

export function removeStorageSync(key) {
  let value = sessionStorage.removeItem(key)
  if (isJSON(value)) return JSON.parse(value)
  return value
}

export function setLocalStorage(key, value){
    let str = typeof value !== 'string' ? JSON.stringify(value) : value
    localStorage.setItem(key, str)
  }
  
export function getLocalStorage(key){
    let value = localStorage.getItem(key)
    if (isJSON(value)) return JSON.parse(value)
    return value
}
  
export function removeLocalStorage(key){
    localStorage.removeItem(key)
}

export function isJSON (str){
    if (typeof str === 'string') {
      try {
        var obj = JSON.parse(str)
        if (obj && typeof obj === 'object') {
          return true
        } else {
          return false
        }
      } catch (e) {
        // console.warn("error：" + str + ">>>" + e);
        return false
      }
    }
    // console.log("It is not a string!");
    return false
  }

// export function removeStorageSync(key) {
    
//     if (window.wx != undefined) {
//        return  window.wx.removeStorageSync(key);
//     }
//     else {
//         return Taro.removeStorageSync(key);
//     }
// }

// export const isJSON = str => {
//     if (typeof str === 'string') {
//         try {
//             var obj = JSON.parse(str)
//             if (obj && typeof obj === 'object') {
//                 return true
//             } else {
//                 return false
//             }
//         } catch (e) {
//             // console.warn("error：" + str + ">>>" + e);
//             return false
//         }
//     }
//     // console.log("It is not a string!");
//     return false
// }
// export const setSessionStorage = (key, value) => {
//     let str = typeof value !== 'string' ? JSON.stringify(value) : value
//     sessionStorage.setItem(key, str)
// }
// export const getSessionStorage = key => {
//     let value = sessionStorage.getItem(key)
//     if (isJSON(value)) return JSON.parse(value)
//     return value
// }

