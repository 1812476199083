
// import {setStorageSync} from '@/app/appstorage'
// import CPCONFIG from '@/app/appconfig'
import {appAdapter} from '../app/appadaper'

const CryptoJS = require('crypto-js')


// 封装常用的请求
export default function makeRequest (opts) {
    return new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest();
      var cfg = {}
      // let sdkVersion = cfg.getSDKVersion().replace('.','');
      // if (parseInt(sdkVersion) > 272) {
      //   // if (window.wx != undefined && opts.method == 'GET') { 
      //   //   xhr = new window.wx.XMLHttpRequest();
      //   // }
      // }
      // var xhr = new window.wx.XMLHttpRequest();
      
      if (opts.responseType){
          xhr.responseType = opts.responseType;
      }
      else {
        xhr.responseType = 'json';
      }
      if (opts.withCredentials) {
          xhr.withCredentials = opts.withCredentials;
      }
 
      let url = opts.url;
      // 如果是get模式 就用query string 
      if (opts.params && typeof opts.params === 'object') {
        let tmpParams = Object.keys(opts.params).map(function (key) {
          return encodeURIComponent(key) + '=' + encodeURIComponent(opts.params[key]);
        }).join('&');
        if (tmpParams.length >0) {
          url = url + '?' + tmpParams;
        }
      }

      xhr.open(opts.method, url);
      
      xhr.onload = function () {
        
        // 如果收到301 就需要退出的用户 清除本地的用户信息
        if (this.status == 301) {
          // Taro.eventCenter.trigger('wapp_logout','1');
        }

        if (this.status >= 200 && this.status < 300) {

          if (opts.responseType == 'arraybuffer') {

          }
          let headers=xhr.getAllResponseHeaders();
          let arr = headers.trim().split(/[\r\n]+/);
          var headerMap = {};
          arr.forEach(function (line) {
            var parts = line.split(': ');
            var header = parts.shift();
            var value = parts.join(': ');
            headerMap[header] = value;
          });let data = xhr.response
          // console.log("encData:",xhr.response);
          
          let keyStr = "lw/il+BCCrOuMxLB";
          let encryptedStr = data.encryptedData;
          // console.log("decString 1:");
          let key = CryptoJS.enc.Utf8.parse(keyStr)
          // console.log("decString 2:");
          let decrypt = CryptoJS.AES.decrypt(encryptedStr, key,
              {
                  mode:CryptoJS.mode.ECB,
                  padding:CryptoJS.pad.Pkcs7,
              },
          )
          // console.log("decString 3:"+decrypt);
          let decString = decrypt.toString(CryptoJS.enc.Utf8);
          // console.log("decString:"+decString);
          data.data = JSON.parse(decString);
          console.log("result - - -- - data:",data);
          let res = {
            headers: headerMap,
            data: data
          }
  

          // 统一处理sql和authorize的处理

          let seq = headerMap['seq'];
    
          if (seq != undefined && seq.length >0) {

            // appAdapter().getStore().storeSeq(seq);
          }
          let auth = headerMap['authorization'];
          if (auth == undefined || auth.length <=0) {
            auth = headerMap['Authorization'];
          }
          if (auth != undefined && auth.length >0) {

            appAdapter().getStore().storeAuthorization(auth);
            // Taro.eventCenter.trigger('weapp_auth_change',1);
          }
          resolve(res);
        } else {
          reject({
            status: this.status,
            statusText: xhr.statusText
          });
        }
      };
      xhr.onerror = function () {
        reject({
          status: this.status,
          statusText: xhr.statusText
        });
      };
      if (opts.headers) {
        Object.keys(opts.headers).forEach(function (key) {
          xhr.setRequestHeader(key, opts.headers[key]);
        });
      }
      var params = opts.params;
      // 如果是post 模式 body里面就用json数据
      var requestType = opts.requestType;
      if (requestType == 'json') {
        let s = JSON.stringify(params);

        // if (parseInt(sdkVersion) > 272) {
        //   // if (window.wx != undefined && opts.method == 'GET') {
        //   //   xhr.setRequestHeader('content-type','application/json');
        //   //   xhr.setRequestHeader('Content-Type','application/json');
        //   // }
        // }
        xhr.send(s);
      }
      else {
            // We'll need to stringify if we've been given an object
      // If we have a string, this is skipped.
      
      if (params && typeof params === 'object') {
        params = Object.keys(params).map(function (key) {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
        }).join('&');
      }
      xhr.send(params);
      }
   
    });
  }


export  function makeTRequest (opts) {
    return new Promise(function (resolve, reject) {
      // var xhr = new wx.XMLHttpRequest();
      let xhr = {}
      // var cfg = new CPCONFIG()
      let cfg = {}
   
     
      if (opts.responseType){
          xhr.responseType = opts.responseType;
      }
      else {
        xhr.responseType = 'json';
      }
      if (opts.withCredentials) {
          xhr.withCredentials = opts.withCredentials;
      }
 
      let url = opts.url;
      xhr.method = 'POST';

      xhr.open(opts.method, url);
      
      xhr.onload = function () {
        
        // 如果收到301 就需要退出的用户 清除本地的用户信息
        if (this.status == 301) {
          // Taro.eventCenter.trigger('wapp_logout','1');
        }

        if (this.status >= 200 && this.status < 300) {

          if (opts.responseType == 'arraybuffer') {

          }
          let headers=xhr.getAllResponseHeaders();
          let arr = headers.trim().split(/[\r\n]+/);
          var headerMap = {};
          arr.forEach(function (line) {
            var parts = line.split(': ');
            var header = parts.shift();
            var value = parts.join(': ');
            headerMap[header] = value;
          });
          let res={
            headers:headerMap,
            data:xhr.response
          }

          // 统一处理sql和authorize的处理

          let seq = headerMap['seq'];
    
          if (seq != undefined && seq.length >0) {
            // setStorageSync('weapp_seq',seq);
          }
          let auth = headerMap['authorization'];
          if (auth != undefined && auth.length >0) {
            // setStorageSync('weapp_authorization',auth);
            setTimeout(() => {
              // Taro.eventCenter.trigger('weapp_auth_change',1);
            }, 100);
          
          }
          resolve(res);
        } else {
          reject({
            status: this.status,
            statusText: xhr.statusText
          });
        }
      };
      xhr.onerror = function () {
        reject({
          status: this.status,
          statusText: xhr.statusText
        });
      };
      if (opts.headers) {
        Object.keys(opts.headers).forEach(function (key) {
          xhr.setRequestHeader(key, opts.headers[key]);
        });
      }
      var params = opts.params;
      // 如果是post 模式 body里面就用json数据
      var requestType = opts.requestType;
      if (requestType == 'json') {
        let s = JSON.stringify(params);

        // if (parseInt(sdkVersion) > 272) {
        //   // if (window.wx != undefined && opts.method == 'GET') {
        //   //   xhr.setRequestHeader('content-type','application/json');
        //   //   xhr.setRequestHeader('Content-Type','application/json');
        //   // }
        // }
        xhr.send(s);
      }
      else {
            // We'll need to stringify if we've been given an object
      // If we have a string, this is skipped.
      
      if (params && typeof params === 'object') {
        params = Object.keys(params).map(function (key) {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
        }).join('&');
      }
      xhr.send(params);
      }
   
    });
  }