// // 域名管理 先获取域名 
import REQUEST, {
  // makeTRequest
} from './../request/baserequest'
import makeCommonHeader from './../request/commonparams'
import {
  setStorageSync, getStorageSync
  // , setLocalStorage, getLocalStorage, removeLocalStorage
} from '../app/appstorage'
import { hexMD5 } from '../request/md5';
// // import { makeTaitanCommonHeader } from '@/request/commonparams';

let SERVERURL = '';
//   /* process.env.NODE_ENV
//     1、pre 代表打包予发布环境
//     2、prod 代表打包生产环境
//   */
let env = 'prod'; //  决定打包环境     
if (env === 'production' || env === 'prod') {
  SERVERURL = 'https://ddapp.cukeindia.work'

} else if (env === 'development' || env === 'pre') {
  SERVERURL = 'https://ddapi.maiuym.work'

} else {//测试
  SERVERURL = 'http://192.168.0.127:9000'
}

// 拼接图片地址
function loadDomainData() {
  return getStorageSync('domain')
}

// 服务URL配置
export default class SERVERCONFIG {

  // 常用api地址
  CPSERVERURLS = []
  // 视频浏览地址
  CPVIDEORULS = [];
  // 视频播放域名权重
  CPVIDEOWEIGHT = []
  // 视频封面
  CPPICTUREURLS = []
  CPPICTUREURLS_NEW = []
  // 图片域名
  CPUPLOADIMAGEURLS = []
  // 图片上传域名
  CPPATIMAGEUPLOADURLS = []
  uploadImageIndex = 0;
  pictureIndex = 0;
  videoIndex = 0
  pictureBucketIndex = 0;

  videoUrl() {
    var url = this.defaultUrl();
    if (this.videoIndex > this.CPVIDEORULS.length) {
      this.videoIndex = 0;
    }

    if (this.CPVIDEORULS.length === 0) {
      return url;
    }

    url = this.CPVIDEORULS[this.videoIndex];
    return url;
  }
  coverUrl() {
    var url = this.defaultUrl();
    if (this.pictureIndex > this.CPPICTUREURLS.length) {
      this.pictureIndex = 0;
    }
    if (this.CPPICTUREURLS.length === 0) {
      return url;
    }

    url = this.CPPICTUREURLS[this.pictureIndex];
    return url;
  }
  coverUrl_new() {
    var url = this.defaultUrl();
    if (this.pictureBucketIndex > this.CPPICTUREURLS_NEW.length) {
      this.pictureBucketIndex = 0;
    }
    if (this.CPPICTUREURLS_NEW.length == 0) {
      return url;
    }
    url = this.CPPICTUREURLS_NEW[this.pictureBucketIndex];
    return url;
  }
  imageUpload() {

    var url = this.defaultUrl();

    if (this.uploadImageIndex > this.CPUPLOADIMAGEURLS.length) {
      this.uploadImageIndex = 0;
    }

    if (this.CPUPLOADIMAGEURLS.length == 0) {
      return url;
    }

    url = this.CPUPLOADIMAGEURLS[this.uploadImageIndex];
    return url;

  }
  defaultUrl() {
    let defaultUrl = window.homeSite;
    if (defaultUrl === undefined) {
      defaultUrl = 'https://www.xy26.app';
    }
    return defaultUrl;
  }
}

// 通过window来保存SERVERCONFIG请求对象
export function getServerCfg() {

  // 如果加载了数据 就不需要读取本地的数据
  if (window.serverconfig !== undefined) {
    let urls = window.serverconfig.CPSERVERURLS;
    if (urls !== undefined && urls.length > 0) {
      return window.serverconfig;
    }
  }
  // 如果没有 就加载本地的数据 
  let res = loadDomainData();
  let cfg = new SERVERCONFIG();
  if (res !== undefined && res !== null && res.length > 0) {
    let json = res;
    if (typeof json === 'string') {
      json = JSON.parse(json);
    }
    if (json.data.length > 0) {
      cfg.CPSERVERURLS = json.data[0].server;
      cfg.CPVIDEORULS = json.data[0].viewVideo2;
      cfg.CPPICTUREURLS = json.data[0].videoCover;
      cfg.CPPICTUREURLS_NEW = json.data[0].videoCoverAddr2;//图片域名key   
      cfg.CPPIMAGEURLS = json.data[0].viewPicture;
      cfg.CPSHAREURLS = json.data[0].shareDomain;
      cfg.CPVIDEOWEIGHT = json.data[0].viewVideoWeight2;
      cfg.CPPATAPIURLS = json.data[0].patApiDomain;
      cfg.CPPATIMAGEDOWNURLS = json.data[0].patImgDownUrl;
      cfg.CPPATIMAGEUPLOADURLS = json.data[0].patImgUpUrl;
      cfg.CPUPLOADIMAGEURLS = json.data[0].pictureUpload;
      cfg.CPMOVSHARE = json.data[0].movShare;

      window.serverconfig = cfg;
    } else {
      window.serverconfig = cfg;
    }
  } else {
    window.serverconfig = cfg;
  }
  return window.serverconfig;
}

//[{"backUpBlogDomain":["http://192.168.0.127:4449/cuke-website/blog.html"],"server":["http://192.168.0.127:9000/"],"backUpIP":["http://192.168.0.127:9000/"],"viewVideo2":["http://192.168.0.122:9237/video/"],"videoUpLoad":["http://192.168.0.122:9227/upload/video/"],"backUpDomain":["http://192.168.0.127:4449////TTTTT/app.html","http://192.168.0.127:9000/","http://www.baidu.com","http://192.168.0.127:9000/","http://192.168.0.127:9000/","http://192.168.0.127:9002/"],"viewPicture":["http://192.168.0.122:9127/img/"],"shareDomain":["http://192.168.0.127:9000/share"],"videoCover":["http://192.168.0.122:9427/cover"],"pictureUpload":["http://192.168.0.122:9127/upload/img/"],"viewVideo":["http://192.168.0.122:9227/video/","http://192.168.0.122:9237/video/"]}]
export function getDomain() {
  return new Promise(function (resolve, reject) {
    let url = SERVERURL;
    var resUrl = '/domain/list'
    resUrl = url + resUrl;
    let actHeaders = makeCommonHeader({}, 'GET', '/domain/list');
    REQUEST({
      url: resUrl,
      method: 'GET',
      withCredentials: false,//是否同步  默认falses
      headers: actHeaders
    }).then(res => {
      let json = res.data;
      let cfg = getServerCfg();
      if (json.code === 0) {
        // 常用api地址
        cfg.CPSERVERURLS = json.data[0].server;
        cfg.CPVIDEORULS = json.data[0].viewVideo2;
        // 视频播放域名权重
        cfg.CPVIDEOWEIGHT = json.data[0].viewVideoWeight2;
        // 视频封面
        cfg.CPPICTUREURLS = json.data[0].videoCover;
        cfg.CPPICTUREURLS_NEW = json.data[0].videoCoverAddr2;//图片域名key   
        // 图片域名
        cfg.CPUPLOADIMAGEURLS = json.data[0].pictureUpload;
        // 图片上传域名
        cfg.CPPATIMAGEUPLOADURLS = json.data[0].patImgUpUrl;
        console.log('domain Data:', cfg);
        // 存储请求的域名数据 
        if (cfg.CPSERVERURLS) {
          setStorageSync('domain', cfg);
        }
        resolve(cfg);
      }
      // callback(res);
    }).catch(err => {
      console.log('域名获取错误');
      console.log(err);
      reject(err);
    });
  })
}
export function getBucket() {
  return new Promise(function (resolve, reject) {
    let url = SERVERURL;
    var resUrl = '/domain/bucket/relation'
    resUrl = url + resUrl;
    let actHeaders = makeCommonHeader({}, 'GET', '/domain/bucket/relation');
    REQUEST({
      url: resUrl,
      method: 'GET',
      withCredentials: false,//是否同步  默认falses
      headers: actHeaders
    }).then(res => {
      // debugger
      let json = res.data;
      let data = json.data
      setStorageSync('bucketData', data);
    }).catch(err => {
      console.log(err);
      reject(err);
    });
  })
}

function serializeParams(params) {
  if (!params) {
    return ''
  }
  return Object.keys(params)
    .map(key => (
      `${encodeURIComponent(key)}=${typeof (params[key]) === 'object'
        ? encodeURIComponent(JSON.stringify(params[key]))
        : encodeURIComponent(params[key])}`))
    .join('&')
}

function requestData(path, method, params, cache = false) {
  return new Promise(function (resolve, reject) {

    let cacheKey = '';

    if (method === 'GET') {
      cacheKey = hexMD5(path, serializeParams(params));
    }

    let responseData = null;
    if (cache && method === 'GET') {
      responseData = getStorageSync(cacheKey);
      if (responseData && responseData.length > 0) {
        resolve(JSON.parse(responseData));
      }
    }

    let url = SERVERURL;
    var realUrl = url + path;

    if (params === undefined) {
      params = {};
    }
    let headers = makeCommonHeader(params, method, path);
    var requestType = 'form';
    if (method === 'POST') {
      requestType = 'json';
    }

    REQUEST({
      url: realUrl,
      method: method,
      withCredentials: false,
      headers: headers,
      params: params,
      requestType: requestType
    }).then(res => {

      // 在这中情况下，需要支持缓存
      if (cache && method === 'GET') {
        if (res.data !== undefined && res.data.code !== undefined && res.data.code === 0) {
          let jsonData = JSON.stringify(res);
          setStorageSync(cacheKey, jsonData);
        }
      }

      resolve(res);

    }).catch(res => {
      reject(res);
    });
  });
}

//   //  普通的请求数据
export function getData(path, params, cache = false) {
  let url = SERVERURL; // 通讯域名
  if (url === undefined || url.length === 0) {

    // let res = loadDomainData();
    return;
  } else {
    return requestData(path, 'GET', params, cache);
  }
}


export function postData(path, params) {

  let url = SERVERURL;
  if (url === undefined || url.length === 0) {

    loadDomainData().then(res => {
      return requestData(path, 'POST', params, false);
    }).catch(() => {
    });
    return;
  } else {
    return requestData(path, 'POST', params, false);
  }
}

// export function postTaitanData(url, params) {
//     return new Promise(function (resolve, reject) {
//       if (params === undefined) {
//         params = {};
//       }
//       let headers = makeTaitanCommonHeader(params, url);

//       makeTRequest({
//         url: url,
//         method: 'POST',
//         withCredentials: false,
//         headers: headers,
//         params:params,
//         requestType:'form'
//       }).then(res => {
//         resolve(res);
//       }).catch(res => {
//         reject(res);
//       });
//     });
// }