import React from 'react';
import { Button, Select, Table, Tabs, Modal, notification, Breadcrumb, Upload } from 'antd';
import CPUplode from "../../Datas/Uplode/index";
// import {appAdapter} from '../../app/appadaper'
import { videoCover, CPVideoUrl } from './../../app/CPUtils'
import AlertVideo from './../../components/AlertVideo/index'
import './index.scss'
import { appAdapter } from '../../app/appadaper'
import logo from '../../asset/image/black_logo@2x 2.png'
import playBtn from '../../asset/image/smallvideo_play@2x.png'
import { GetUploadManager } from './../../Datas/Uplode/upload'
import CPUpload from './../../Datas/Uplode/index'



// import { Slider } from 'element-react';
const { Option } = Select;
const TabPane = Tabs.TabPane;
const confirm = Modal.confirm;

export default class UploadList extends React.Component {
    constructor() {
        super(...arguments);
        this.upload = new CPUpload();
        this.columns = [
            {
                title: '视频详情',
                dataIndex: 'image',
                key: 'image',
                render: (record, e) => {
                    // playBtn
                    return (
                        <div className="coverVideo">
                            <img src={playBtn} className="coverVideo-play" alt="" onClick={this.playVideo.bind(this, e)} />
                            <img src={record} className="coverVideo-cover" alt="" onClick={this.playVideo.bind(this, e)} />
                        </div>)
                },
                width: '20%'
            },
            {
                title: '视频标题',
                dataIndex: 'title',
                key: 'title',
                width: '20%'
            },
            {
                title: '视频类型',
                dataIndex: 'type',
                key: 'type',
                render: (record) => {
                    if (record === 1) {
                        return <div className="MovType1">上传成功</div>
                    } else if (record === 2) {
                        return <div className="MovType2">上传失败，请重试</div>
                    } else if (record === 3) {
                        return <div className="MovType1">正在上传30%</div>
                    } else if (record === 4) {
                        return <div className="MovType1">等待上传</div>
                    }
                },
                width: '20%'
            },
            {
                title: '审核状态',
                dataIndex: 'state',
                key: 'state',
                width: '20%'
            },
            {
                title: '操作',
                dataIndex: 'edit',
                key: 'edit',
                render: (record, e) => {
                    return (
                        <Button key={e.id} type="primary" onClick={this.delMov.bind(this, e)} danger size={'large'}>{record}</Button>
                    )
                },
                width: '20%'
            }
        ];
        this.rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.selectArr = selectedRows
            },
            onSelect: (record, selected, selectedRows) => {
                this.selectArr = selectedRows
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
                this.selectArr = selectedRows
            },
        };


        let _this = this;
        this.uploadBtn = {
            onChange({ file, fileList, e }) {
                console.log(file, fileList);

               

                if (file.status !== 'uploading') {


                     // 统一处理格式
                    // 检查hash是否存在 
                    GetUploadManager().calculateMD5Hash(file.originFileObj, 10 * 1024 * 1024).then(res => {
                        let hash = res;
                        _this.upload.checkFile(res).then(res => {
                            if (res.code == 0) {
                                if (GetUploadManager().hasUplaodObject(hash)) {
                                    // window.alert('该上传文件已经在上传列表中')
                                    let data = _this.currentlySelectedFile.uploadMeta;
                                    GetUploadManager().publishVideo(file.originFileObj, _this.currentlySelectedFile.uploadMeta, hash);
                  
                                }
                                else {
                                    let data = _this.currentlySelectedFile.uploadMeta;
                                    GetUploadManager().publishVideo(file.originFileObj, _this.currentlySelectedFile.uploadMeta, hash);
                                }
                            } else {
                                window.alert('文件已经上传重复了')
                            }
                        }).catch(err => {});

                    }).catch(err => {
                        console.log('检查hash出错');
                    });


                    // GetUploadManager().getFileSize(window.mediaInfoHandler,info.file.originFileObj).then(res=>{
                    //     console.log('获取文件信息'+res);

                   

                    // }).catch(err=>{

                    // });

                   

                    // this.currentlySelectedFile = e
                    // GetUploadManager().pushUploadObject(e)
                }
            }
        };
        this.columnsUping = [
            {
                title: '视频详情',
                dataIndex: 'image',
                key: 'image',
                render: (record) => {
                    //  console.log(record)
                    return (
                        <div style={{ width: '250px', height: '140px', background: '#f6f8f9' }}>
                            <img src={logo} alt="" style={{ width: '100px', height: '100px', margin: '20px 75px', objectFit: 'cover', background: '#f6f8f9' }} />
                        </div>
                    )
                },
                width: '20%'
            },
            {
                title: '视频标题',
                dataIndex: 'title',
                key: 'title',
                width: '20%'
            },
            {
                title: '上传状态', //  0 表示没有上传 1 表示上传中 3 表示上传失败 4 表示上传成功 
                dataIndex: 'uploadState',
                key: 'uploadState',
                render: (record, e) => {
                    if (record === 4) {
                        return <div className="MovType1">上传成功</div>
                    } else if (record === 3) {
                        return <div className="MovType2">上传失败，请重试</div>
                    } else if (record === 1) {
                        let Process = e.uploadProcess * 100
                        return <div className="MovType1">正在上传{parseFloat(Process).toFixed(2)}%</div>
                    } else if (record === 0) {
                        return <div className="MovType1">等待上传</div>
                    }
                    else if (record ==5) {
                        return <div className="MovType2">已停止上传</div>
                    }
                },
                width: '20%'
            },
            {
                title: '操作',
                dataIndex: 'edit',
                key: 'edit',
                render: (record, e) => {
                    let state = e.uploadState
                    let actionTitle = '';
                    let actionHandler = undefined;
                    let isNeedUpload = false;
                    if (state == 3 || state ==5) {
                        actionTitle = '重新上传';
                        actionHandler = this.uploadonChange;
                        isNeedUpload = true;
                    }
                    else if (state == 1) {
                        actionTitle = '暂停上传';
                        actionHandler = this.stopUploadChange;
                        isNeedUpload = false;
                    }
                    else if (state == 0) {
                        actionTitle = '开始上传';
                        actionHandler = this.startUploadChange;
                    } 


                    return (
                        <div>
                            {(isNeedUpload === true) &&<Upload showUploadList={false} {...this.uploadBtn}>
                                <Button
                                    onClick={actionHandler.bind(this, e)}
                                    style={{ background: '#FF487D', border: 'none', marginRight: '15px' }}
                                >{actionTitle}</Button>
                            </Upload>}

                            {(isNeedUpload === false) &&  <Button
                                    onClick={actionHandler.bind(this, e)}
                                    style={{ background: '#FF487D', border: 'none', marginRight: '15px' }}
                                >{actionTitle}</Button>}
                    
                            <Button type="primary" onClick={this.goToEdit.bind(this, e)} size={'large'} style={{ background: '#CCCCCC', border: 'none', marginRight: '15px' }}>{"编辑"}</Button>
                            <Button type="primary" onClick={this.delMov.bind(this, e)} danger size={'large'}>{"删除"}</Button>
                        </div>
                    )
                },
                width: '40%'
            }
        ]
        this.state = {
            dataList: [],
            renderUploadOk: {
                columns: this.columns,
                rowSelection: this.rowSelection,
                typeSelectList: [
                    {
                        key: '长视频',
                        value: '长视频',
                        pageNo: 1,
                        pageSize: 10,
                        type: 1,
                        pageCount: 0,
                        count: 0
                    },
                    {
                        key: '短视频',
                        value: '短视频',
                        pageNo: 1,
                        pageSize: 10,
                        type: 3,
                        pageCount: 0,
                        count: 0
                    }
                ],
                MovListLong: [],
                MovListShort: [],
                currentType: 1,
                action : -1 // 重新上传 
            },
            renderUploading: {
                columns: this.columnsUping,
                rowSelection: this.rowSelection
            },
            openAlertVideo: false
        }

        this.CPUplode = new CPUplode();
        this.item = {}
        this.newMovListLong = []; // 长视频
        this.pageNoListLong = []
        this.newMovListShort = []; // 短视频
        this.pageNoListShort = []
        this.newMovListStorge = []; // 上传中的视频
        this.isStorage = false
        this.selectArr = []
        this.group = {
            address: {
                "480P": "/cukefree/4058/CF685A99AC3052FD362D33ECAF19134D/CF685A99AC3052FD362D33ECAF19134D_9bf66cf92e6043ce9b0ba02de80088b5.mp4/index.m3u8"
            },
            aliasName: "",
            allCovers: {
                horizontal_large: "",
                vertical_large: "/cukefree/android/50f1b85e3648e3bb9eaaf3feec8a4416.jpg"
            },
            allJCovers: {
                horizontal_large: "",
                vertical_large: "/cukefree/android/50f1b85e3648e3bb9eaaf3feec8a4416.jpg"
            },
            clsId: 0,
            commentCnt: 0,
            content: "",
            cover: "/cukefree/android/50f1b85e3648e3bb9eaaf3feec8a4416.jpg",
            favorCnt: 0,
            findMovSucess: 0,
            gmtCreate: "2020-09-13",
            gmtCreateStr: "2020-09-13 10:34:01",
            hasDownload: false,
            hasFavor: false,
            icon: "",
            id: 1295,
            inviteCode: "",
            isFavoed: 0,
            isHot: 0,
            isMcn: 0,
            isUped: 0,
            isUper: 0,
            isWanghong: 0,
            isZhenren: 0,
            jCover: "",
            jThumbnail: "",
            mins: "00:00:09",
            minsOri: "00:00:09",
            movDesc: "hrjrj",
            movName: "urjr0",
            movPackageId: 0,
            movScore: "",
            movSize: {},
            movSnOri: "",
            name: "",
            navId: 0,
            playCnt: 0,
            playCntStr: "0次播放",
            primevalCover: "{\"horizontal_large\":\"\",\"vertical_large\":\"/cukefree/android/50f1b85e3648e3bb9eaaf3feec8a4416.jpg\"}",
            resourceType: 3,
            selectionType: 0,
            selector: "",
            state: -1,
            tagName: [],
            thumbnail: "/cukefree/android/50f1b85e3648e3bb9eaaf3feec8a4416.jpg",
            topicId: 0,
            topicName: "",
            upState: 0
        }
        this.currentlySelectedFile = {}
        this.setTimeoutStore = undefined
        //  定时刷新列表
        
    

        



        this.setTimeoutStore = setInterval(() => {
            
            let list = appAdapter().getStore().getLocalUploadList()
            if (!list) {
                list = [];
            }

            this.setState({
                dataList: [].concat(list)
            })
    
        }, 2000);
    }
    componentDidMount() {
        let wapp_user = appAdapter().getStore().getWappUser()

        if(wapp_user && wapp_user.myInviteCode){
        } else {
            this.props.history.push({ pathname: '/Login', state: { name: 'sunny' } })
           // this.getVisitorToken()
        }
        setTimeout(() => {
            this.callback(1) // 默认上传成功标签栏
        }, 1000);
    }
    uploadonChange(e) {
        this.currentlySelectedFile = e
    }
    stopUploadChange(e) {
        // console.log(e)
        // GetUploadManager().stopUpload(e.uid)
        confirm({
            title: '暂停之后之前上传的进度丢失，恢复需要重新上传',
            okText: '确定',
            cancelText: '取消',
            // content: '一些解释',
            onOk() {
                GetUploadManager().stopUpload(e.uid)
            },
            onCancel() {
                
            }
        });

    }
    startUploadChange(e) {
        this.currentlySelectedFile = e
    }
    goToEdit(e) {

        // actionState 3 表示编辑 
        this.props.history.push({ pathname: '/uploadvideo', query: { obj: e , actionState : 3} })
    }
    callback(e) {
        if (e == 1) {
            this.isStorage = false // 默认接口请求长视频
        } else if (e == 2) {
            this.isStorage = true  // 默认缓存获取长视频
            this.newMovListLong = []; // 长视频
            this.pageNoListLong = []
            this.newMovListShort = []; // 短视频
            this.pageNoListShort = []
            this.setState({
                renderUploadOk:{
                    columns: this.columns,
                    rowSelection: this.rowSelection,
                    typeSelectList: [
                        {
                            key: '长视频',
                            value: '长视频',
                            pageNo: 1,
                            pageSize: 10,
                            type: 1,
                            pageCount: 0,
                            count: 0
                        },
                        {
                            key: '短视频',
                            value: '短视频',
                            pageNo: 1,
                            pageSize: 10,
                            type: 3,
                            pageCount: 0,
                            count: 0
                        }
                    ],
                    MovListLong: [],
                    MovListShort: [],
                    currentType: 1
                }
            })
        }
        this.handleChange('长视频', e)
    }
    playVideo(e) {
        //  console.log(e)
        this.item = e
        this.setState({
            openAlertVideo: true
        })
    }
    cloAlertVideo() {
        this.setState({
            openAlertVideo: false
        })

    }
    delMov(e) {
        let arr = [e]
        this.removeFromArr(arr)
    }
    batchDeletion() {
        this.removeFromArr(this.selectArr)
    }
    removeFromArr(arr) {
        if (arr.length < 1) return
        let list = [], _this = this, arrIdString = '', uploadIng = false, uids = []
        let { renderUploadOk } = this.state

        if (this.isStorage) { // 上传中列表
            arr.map(data => {
                if(data.uploadState == 1){
                    uploadIng = true
                }
                uids.push(data.uid)
            })
            this.handleChange('长视频', 2)
        } else {
            if (renderUploadOk.currentType === 1) {
                list = renderUploadOk.MovListLong
            } else if (renderUploadOk.currentType === 3) {
                list = renderUploadOk.MovListShort
            }
            arr.map(data => {
                arrIdString = arrIdString + ',' + `${data.id}`
                list.map((item, index) => {
                    if (data.id === item.id) {
                        list.splice(index, 1)
                    }
                })
            })
        }

        let title = arr.length > 1 ? '您是否要批量删除此视频' : '您是否要删除此视频'
        if(uploadIng) {
            title = '您是否要删除正在上传的视频'
        }
        confirm({
            title: title,
            okText: '删除',
            cancelText: '取消',
            // content: '一些解释',
            onOk() {
                if (_this.isStorage) { // 上传中列表
                    _this.newMovListStorge = [].concat(list)
                    GetUploadManager().removeUploadLists(uids)
                    // let list = appAdapter().getStore().setLocalUploadList()
                } else {
                    if (renderUploadOk.currentType === 1) {
                        _this.newMovListLong = [];
                        _this.newMovListLong.push(...list)
                        renderUploadOk.MovListLong = [].concat(_this.newMovListLong)
                    } else if (renderUploadOk.currentType === 3) {
                        _this.newMovListShort = [];
                        _this.newMovListShort.push(...list)
                        renderUploadOk.MovListShort = [].concat(_this.newMovListShort)
                    }
                    _this.setState({
                        renderUploadOk: renderUploadOk
                    })
                    _this.delUpMov(arrIdString)
                }
            },
            onCancel() {
                _this.selectArr = []
            }
        });


    }
    handleChange(value) { // 切换长视频 || 短视频
        let { renderUploadOk } = this.state
        let obj = {}
        renderUploadOk.typeSelectList.map(item => {
            if (value === item.value) {
                obj = item
            }
        })
        
        if (this.isStorage) {
            if(this.setTimeoutStore != undefined){
                
            }
            // this.queryListInStorage(obj)
        } else {
            this.queryUploadList(obj)
        }
    }
    delUpMov(id) {
        this.CPUplode.delUpMov(id).then(res => {
            notification['success']({
                message: '删除成功'
            });
        }).catch(err => {

        })
    }
    queryUploadList(item) { // 接口请求
        // let isGoOn = this.getIsGoOn(item)
        // if(isGoOn) {
        //     return
        // }
        let { renderUploadOk } = this.state
        let obj = {
            pageNo: item.pageNo,
            pageSize: item.pageSize,
            type: item.type
        }
        let datas
        this.CPUplode.queryUploadList(obj).then(res => {
            //  console.log(res.data)
            let dataList = res.data
            datas = this.handleData(dataList)
            //  console.log(item.type)
            renderUploadOk.currentType = item.type
            if (item.type === 1) { // 长视频
                renderUploadOk.typeSelectList[0].count = res.count
                this.newMovListLong = this.handleList(item, this.newMovListLong, datas)
                renderUploadOk.MovListLong = [].concat(this.newMovListLong)
            } else if (item.type === 3) { // 短视频
                renderUploadOk.typeSelectList[1].count = res.count
                this.newMovListShort = this.handleList(item, this.newMovListShort, datas)
                renderUploadOk.MovListShort = [].concat(this.newMovListShort)
            }
            console.log('renderUploadOk',renderUploadOk)
            this.setState({
                renderUploadOk: renderUploadOk
            })
        }).catch(err => {

        })
    }
    handleList(item, list, datas) {
        // item, 获取当前页码 
        // list, 现有数据列表
        // datas 新添加的数据
        // this.pageNoListLong   长视频页面列表
        // this.pageNoListStorge 短视频页面列表
        let newList = [], pageNoList = []
        if (item.pageNo === 1) { // 当前第一页
            newList.push(...datas)
            if (item.type === 1) {
                this.pageNoListLong= [1]
            } else if (item.type === 3) {
                this.pageNoListStorge= [1]
            }
        } else { // 其他页
            let isHave = false, maxPageNo = 1
            if (item.type === 1) {
                pageNoList = this.pageNoListLong
            } else if (item.type === 3) {
                pageNoList = this.pageNoListStorge
            }
            pageNoList.map(item => {
                if (item === item.pageNo) {
                    isHave = true
                }
                if (item >= maxPageNo) {
                    maxPageNo = item
                }
            })
            if (!isHave) { // 没有当前页码说明 列表的对应位置是假数据 需要被替换
                let index = item.pageNo * item.pageSize - 10  // 不会出现第一页  当前页的第一条下标
                if (list.length - 1 < index) { // 需要添加元素
                    if (item.pageNo === maxPageNo + 1) { // 如果是下一页
                        list.push(...datas)
                        newList.push(...list)
                    } else { // 随即点击页码 并且页码大于当前最大页码
                        let pageDifference = item.pageNo - maxPageNo - 1 // 需要填补的空白页
                        for (let i = 0; i < pageDifference * 10; i++) {
                            list.push(this.group)
                        }
                        list.push(...datas)
                        newList.push(...list)
                    }
                } else { // 替换数组中的元素
                    list.splice(index, item.pageSize, ...datas);
                    newList.push(...list)
                }
                if (item.type === 1) {
                    this.pageNoListLong.push(item.pageNo)
                } else if (item.type === 3) {
                    this.pageNoListStorge.push(item.pageNo)
                }
            }
        }
        return newList
    }

    handleData(list) {
        // console.log(list)
        let datas = []
        list.map((item, i) => {
            let imageUrl = videoCover(item.cover)
            if (!item.cover) {
                imageUrl = videoCover(item.jCover)
            }
            let state = '待审核'
            // item.state
            // 0、待审核，1、审核通过；2、审核不通过；-1、删除；不是这里面的数据，就做0处理
            let stateList = ['已删除', '待审核', '审核通过', '审核不通过']
            stateList.map((items, index) => {
                if (item.state + 1 === index) {
                    state = items
                }
            })
            let videoName = '';
            if (item.address['480P']) {
                videoName = item.address['480P'];
            } else if (item.address['360P']) {
                videoName = item.address['360P'];
            }
            else if (item.address['720P']) {
                videoName = item.address['720P'];
            }
            videoName = CPVideoUrl(videoName)

            let data = {
                id: item.id,
                mins: item.mins,
                videoUrl: videoName,
                image: imageUrl,
                title: item.movName,
                state: state,
                type: 1, // 1. 上传成功 2。上传失败，请重试 3. 正在上传${} 4.等待上传
                edit: '删除',
                key: i
            }
            datas.push(data)
        })
        return datas
    }
    switchPageNum(e) {
        let { renderUploadOk } = this.state
        let typeSelectList = renderUploadOk.typeSelectList
        let currentType = renderUploadOk.currentType
        let index
        if (currentType === 1) {
            index = 0
        } else {
            index = 1
        }
        let item = {
            pageNo: e,
            pageSize: typeSelectList[index].pageSize,
            type: currentType,
        }
        this.queryUploadList(item)
    }
    renderUploadOk() {
        let { renderUploadOk } = this.state
        let dataList = []
        let listObj = {}
        // console.log('renderUploadOk', renderUploadOk)
        if (renderUploadOk.currentType === 1) {
            dataList = renderUploadOk.MovListLong
            listObj = renderUploadOk.typeSelectList[0]
        } else if (renderUploadOk.currentType === 3) {
            dataList = renderUploadOk.MovListShort
            listObj = renderUploadOk.typeSelectList[1]
        }
        // console.log('listObj', listObj.count)
        return (
            <div className="UploadList">
                <div className="UploadList-top">
                    <div className="UploadList-top-delBtn">
                        <Button type="primary" danger onClick={this.batchDeletion.bind(this)} size={'large'}>批量删除</Button>
                    </div>
                    <div className="UploadList-top-select">
                        <Select defaultValue="长视频" size={'large'} style={{ width: 120 }} onChange={this.handleChange.bind(this)}>
                            {
                                renderUploadOk.typeSelectList.map(item => {
                                    return (<Option value={item.value} key={item.key}>
                                        {item.value}
                                    </Option>)
                                })
                            }
                        </Select>
                    </div>
                </div>
                <div className="UploadList-Table">
                    <Table
                        key="uid2"
                        columns={renderUploadOk.columns}
                        pagination={{ total: listObj.count, defaultCurrent: 1, onChange: this.switchPageNum.bind(this) }}
                        rowSelection={{ ...renderUploadOk.rowSelection }}
                        dataSource={dataList}
                    />
                </div>

            </div>
        )
    }
    renderUploading() {
        let { renderUploading, dataList } = this.state
        //  console.log(dataList)
        return (
            <div className="UploadList">
                <div className="UploadList-top">
                    <div className="UploadList-top-delBtn">
                        <Button type="primary" onClick={this.batchDeletion.bind(this)} danger size={'large'}>批量删除</Button>
                    </div>
                </div>
                <div className="UploadList-Table">
                    <Table
                        key="uid2"
                        columns={renderUploading.columns}
                        rowSelection={{ ...renderUploading.rowSelection }}
                        dataSource={dataList}
                    />
                </div>

            </div>
        )
    }
    render() {
        let { openAlertVideo, dataList } = this.state
        //  console.log(dataList)
        return (
            <div className='Tabs'>
                <Breadcrumb style={{ margin: '0 0 15px' }}>
                    <Breadcrumb.Item>上传助手</Breadcrumb.Item>
                    <Breadcrumb.Item>上传视频</Breadcrumb.Item>
                </Breadcrumb>
                <Tabs defaultActiveKey="1" onChange={this.callback.bind(this)}>
                    <TabPane tab={`上传成功`} key="1">{this.renderUploadOk()}</TabPane>
                    <TabPane tab={`上传中`} key="2">{this.renderUploading()}</TabPane>
                </Tabs>
                {openAlertVideo && <div className="AlertVideo">
                    <Modal title="播放视频" onCancel={this.cloAlertVideo.bind(this)} visible={openAlertVideo} footer={null} width={800} height={500}>
                        <AlertVideo key='1' item={this.item}></AlertVideo>
                    </Modal>
                </div>}
            </div>
        )
    }
}

