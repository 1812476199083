import React, { Component } from 'react';
import { Form, Input, Button, Select, Tag, Modal, Row, Col, message, Upload, Breadcrumb } from 'antd';
import { CheckCircleOutlined, PlusOutlined } from '@ant-design/icons';
import CPUserInfo from "../../Datas/User/index";
import { imageUploadUrl, videoCover } from '../../app/CPUtils'
import { setStorageSync, getStorageSync } from "../../app/appstorage"
import { GetUploadManager } from './../../Datas/Uplode/upload'
import { appAdapter } from '../../app/appadaper'
import selctsrc from '../../asset/image/select@2x.png'
import CPUpload from './../../Datas/Uplode/index'
import { checkedAndUpload, toBlob } from './../../Datas/Uplode/minioutil'
import { hexMD5 } from './../../request/md5';

import './index.scss'
const { Option } = Select;
const { confirm } = Modal;
const { Search } = Input;
const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 21 },
};
const validateMessages = {
    required: '请输入标题',
    types: {
        gender: '${label} is not a validate number!',
    },
};
export default class text1 extends React.Component {
    constructor(props) {
        super(props)
        this.formRef = React.createRef();
        this.upload = new CPUpload();

        this.state = {
            selectAreaList: [],
            charges: [],
            reqeustCls: [],
            requestAllTag: [],
            movCls: '',
            selectTag: [],  // 表单的标签数据
            selecttaglist: [], //弹框选择的标签
            visible: false,
            classvisible: false,
            classlist: [],       //表单的分类数据
            classselectlist: [],//弹框选择的分类数据
            fileobj: '',
            isEdit: false,
            action: -1,
            imageUrl: '',
            imgFileObj: {
                width: 0,
                height: 0,
                minioUrl: ''
            }
        }
        this.user = new CPUserInfo();
        this.propsobj = {
            action: '',
            listType: 'picture',
            fileList: [],
            beforeUpload: file => {
                this.propsobj.fileList = [];
                if (file.type.indexOf('video') < 0) {
                    message.error(`${file.name}不是视频格式，请选择视频上传`);
                    return false;
                }
                let listobj = {
                    movCls: '',
                }
                this.getResourceType(file, listobj)
                this.setState({
                    fileobj: file
                });
                return false;
            },
        };
        this.onSearch = value => {
            let { reqeustCls } = this.state
            let list = []
            if (value.length == 0) {
                this.setState({
                    reqeustCls: [].concat(this.reqeustCls)
                })
                return
            }
            reqeustCls.map(item => {
                if (item.clsName.indexOf(value) != -1) {
                    list.push(item)
                }
            })
            this.setState({
                reqeustCls: [].concat(list)
            })
        }
    }
    componentDidMount() {
        let listobj = {
            filename: '',
            fileobj: "",
            movName: '',
            movDesc: '',
            movCls: '',
            tagids: '',
            localId: '',
            movPackageId: ''
        }
        let objProps = this.props.location.query
        if (objProps && objProps.obj) {
            let obj = objProps.obj
            if (obj.uid) {
                listobj = {
                    filename: obj.title,
                    fileobj: obj.fileobj,
                    movName: obj.uploadMeta.movName,
                    movDesc: obj.uploadMeta.movDesc,
                    movCls: obj.uploadMeta.movCls,
                    tagids: obj.uploadMeta.tagids,
                    localId: obj.uploadMeta.localId,
                    movPackageId: obj.uploadMeta.movPackageId
                }
            }
            this.setState({
                isEdit: true
            })
        }
        if (GetUploadManager().currentSelectUploadObject != undefined) {
            let fileobj = GetUploadManager().currentSelectUploadObject.originFileObj;
            console.log('get file object', fileobj)
            this.getResourceType(fileobj, listobj)
            this.setState({
                fileobj: fileobj
            });
        }

        this.formRef.current.setFieldsValue(listobj);
        this.requestAllTag(listobj)
        this.reqeustCls(listobj)
        this.uploadVideoPackage()
        this.reqeustLocalInfo()
    }
    getResourceType(fileobj, listobj) {
        console.log('*-', this)
        // window.mediaInfoHandler 来源于 index.html  需要开外网
        GetUploadManager().getFileSize(window.mediaInfoHandler, fileobj).then(res => {

            console.log('*-*-*-*-', this)
            let fileObject = JSON.parse(res);
            let mins = fileObject.media.track[0].Duration;
            console.log('获取文件信息', mins);
            let resourceType = 0 // 长视频
            if (mins < 1 * 60) {
                resourceType = 1 // 短视频
            }
            this.reqeustCls(listobj, resourceType)
            // console.log(fileObject);
            // this.pushUploadObject(upload);

        }).catch(err => {
            console.log('获取文件出错', err);
        });
    }
    requestAllTag(listobj) { // 获取 标签
        this.user.requestAllTag().then(res => {
            let items = res.data;
            this.setState({
                requestAllTag: items
            });
            let Tag = [];
            items.forEach((item) => {
                item.subclass.forEach((child) => {
                    let result = listobj.tagids.split(',').find((v) => {
                        return v == child.id;
                    });
                    if (result) {
                        Tag.push(child)
                    }
                })
            })
            this.setState({
                selectTag: Tag
            });
            this.setState({
                selecttaglist: Tag
            });
        }).catch(err => {
            console.log(err);
        });
    }
    reqeustCls(listobj, resourceType) { // 获取分类
        let Type = resourceType;
        if (!resourceType) {
            Type = 0
        }
        this.user.reqeustCls({ type: Type }).then(res => {
            let items = res.data;
            this.setState({
                reqeustCls: items
            });
            this.reqeustCls = items
            let Tag = [];
            items.forEach((child) => {
                let result = listobj.movCls.split(',').find((v) => {
                    return v == child.id;
                });
                if (result) {
                    Tag.push(child)
                }
            })
            this.setState({
                classlist: Tag
            });
            this.setState({
                classselectlist: Tag
            });
        }).catch(err => {
            console.log(err);
        });
    }
    uploadVideoPackage() {
        this.user.uploadVideoPackage().then(res => {
            let items = res.data;
            this.setState({
                charges: items
            });
        }).catch(err => {
            console.log(err);
        });
    }
    reqeustLocalInfo() {
        this.user.reqeustLocalInfo().then(res => {
            let items = res.data;
            this.setState({
                selectAreaList: items
            });
        }).catch(err => {
            console.log(err);
        });
    }
    backclick() {
        this.props.history.push({ pathname: '/UploadList', state: { name: 'sunny' } })
    }
    onReset = () => { // 刷新
        this.formRef.current.resetFields();
        this.setState({ classlist: [] })
        this.setState({ classselectlist: [] });
        this.setState({ selecttaglist: [] })
        this.setState({
            selectTag: [],
            imageUrl: '',
            imgFileObj: {
                width: 0,
                height: 0,
                minioUrl: ''
            }
        })
        this.setState({ fileobj: '' });
    };
    showConfirm = () => { // 显示弹框
        let self = this;
        confirm({
            title: '提交成功',
            icon: <CheckCircleOutlined className="color" />,
            content: '视频上传结果请到内容管理中进行查看',
            okText: '跳转到内容管理',
            cancelText: '取消',
            onOk() {

                // console.log('当前的文件对象',self.state.fileobj);
                // console.log('当前选择的信息',self.state);

                self.props.history.push({ pathname: '/UploadList', state: { name: 'sunny' } })
            },
            onCancel() {
                self.onReset();
            },
        });
    }
    //form表单提交
    onFinish = values => {
        let { imgFileObj } = this.state
        values.coverId = imgFileObj
        console.log('values----', values)
        console.log('objProps----', this.props.location.query)
        if (this.state.isEdit) {
            let objProps = this.props.location.query
            if (objProps != undefined) {
                GetUploadManager().updateVideo(objProps.obj, values);
                this.props.history.push({ pathname: '/UploadList', state: { name: 'sunny' } })
            }
            return;
        }





        console.log(window.mediaInfoHandler);


        // 检查hash是否存在 
        GetUploadManager().calculateMD5Hash(this.state.fileobj, 10 * 1024 * 1024).then(res => {
            let hash = res;
            this.upload.checkFile(res).then(res => {
                if (res.code == 0) {
                    if (GetUploadManager().hasUplaodObject(hash)) {
                        window.alert('该上传文件已经在上传列表中')
                    }
                    else {
                        GetUploadManager().publishVideo(this.state.fileobj, values, hash);
                        this.showConfirm()
                    }
                }
                else {
                    window.alert('文件已经上传重复了')
                }
            }).catch(err => {

            });

        }).catch(err => {
            console.log('检查hash出错');
        });
    };
    handleClose = removedTag => { // 标签 删除
        const tags = this.state.selectTag.filter(tag => tag !== removedTag);
        this.setState({ selectTag: tags })
        this.setState({ selecttaglist: tags });
        let ids = []
        tags.forEach(tag => {
            ids.push(tag.id);
        });

        if (tags.length == 0) {
            this.formRef.current.setFieldsValue({
                tagids: '',
            });
        } else {
            this.formRef.current.setFieldsValue({
                tagids: ids.join(','),
            });
        }
    };
    handleClassClose = removedTag => { // 分类 删除
        const tags = this.state.classlist.filter(tag => tag !== removedTag);
        this.setState({ classlist: tags })
        this.setState({ classselectlist: tags });
        let ids = []
        tags.forEach(tag => {
            ids.push(tag.id);
        });

        if (tags.length == 0) {
            this.formRef.current.setFieldsValue({
                movCls: '',
            });
        } else {
            this.formRef.current.setFieldsValue({
                movCls: ids.join(','),
            });
        }
    };
    setVisible() {
        this.setState({ visible: false });
        this.setState({ classvisible: false });
    }
    addSubmit() {
        if (this.state.selecttaglist.length > 4) {
            message.error({
                content: '只能选取4个标签哟！',
                className: 'custom-class',
                style: {
                    marginTop: '30vh',
                },
            });
        } else {
            let ids = []
            this.state.selecttaglist.forEach(tag => {
                ids.push(tag.id);
            });
            this.formRef.current.setFieldsValue({
                tagids: ids.join(','),
            });
            console.log(this.formRef.current.setFieldsValue())
            this.setState({ selectTag: this.state.selecttaglist })
            this.setState({ visible: false });
        }

    }
    openModel() {
        this.setState({ selecttaglist: this.state.selectTag });
        this.setState({ visible: true });
    }
    openClassModel() {
        this.setState({ classselectlist: this.state.classlist });
        this.setState({ classvisible: true });
    }
    clickCls(item) { // 标签
        let list = []
        var result = this.state.selecttaglist.find((v) => {
            return v == item;
        });
        if (result) {
            this.state.selecttaglist.splice(this.state.selecttaglist.findIndex(li => item === li), 1)
            this.setState({ selecttaglist: this.state.selecttaglist })

        } else {
            list = [...this.state.selecttaglist, ...[item]];
            this.setState({ selecttaglist: list });

        }
    }
    clickClassCls(item) { // 分类
        let list = []
        list.push(item)
        this.setState({
            // classselectlist: list[list.length-1] 
            classselectlist: item
        });
    }
    addClassSubmit() {
        let { classselectlist } = this.state
        let ids = []
        ids.push(classselectlist.id)
        this.formRef.current.setFieldsValue({
            movCls: ids.join(','),
        });
        let list = [classselectlist]
        this.setState({
            classlist: list,
            classvisible: false
        })
    }
    renderImg(item) {
        var result = this.state.selecttaglist.find((v) => {
            return v == item;
        });
        return result ? <img className="selectsrc" src={selctsrc} /> : ''

    }
    renderClassImg(item) {
        let { classselectlist } = this.state
        let result = false
        if (classselectlist) {
            if (item.id == classselectlist.id) {
                result = true
            }
        }
        return result ? <img className="selectsrc" src={selctsrc} /> : ''
    }
    uploadRequest() {
        let _this = this
        var fileM = document.querySelector("#fileimg")
        let fileObj = fileM.files[0]
        let size5M = 1024 * 1024 * 5
        if (fileObj.size > size5M) {
            this.setState({
                uploadLoading: false,
            })
            message.error('太大了哦！换一个小于5M的吧');
            return
        }

        this.upload.getUploadParams().then(res => {
            window.accessKey = res.data[0].accessKey;
            window.secretKey = res.data[0].secretKey;
            window.token = res.data[0].token;
            window.userId = res.data[0].userId;
            _this.onloadImg(fileObj)
        })

    }
    onloadImg(fileObj){
        let _this = this
        var reader = new FileReader(); //声明一个FileReader实例 
        reader.readAsDataURL(fileObj); //调用readAsDataURL方法来读取选中的图像文件 
        //最后在onload事件中，获取到成功读取的文件内容，并且回显
        reader.onload = function (e) {
            _this.setState({
                imageUrl: this.result
            })

            // 获取图片尺寸
            let width = 0,
                height = 0
            let image = new Image()
            image.src = e.target.result
            image.onload = function () {
                width = this.width
                height = this.height
            }
            let url = fileObj.name;

            let picUrl = '';
            if (url) {
                // 上传视频到 minio  参数整理
                let timestamp = new Date().getTime();
                let md5 = hexMD5(url + timestamp)
                picUrl = "PC/" + md5 + ".jpg"
                console.log('picUrl====>', picUrl)
                let blobUrl = toBlob(this.result);
                fileObj.originFileObj = blobUrl
            }
            let obj = {
                file: fileObj,
                fileName: picUrl
            }
            checkedAndUpload(window.bucketName, obj, res => {
                // console.log('///fileObj', obj)
                // 输出url
                console.log(res);
                let imgFileObj = {
                    width: width,
                    height: height,
                    minioUrl: obj.fileName
                }
                _this.setState({
                    imgFileObj: imgFileObj
                })
            });
        }
    }
    render() {
        let { fileobj, classlist, isEdit, imageUrl } = this.state
        let name = '记得选择视频哦'
        if (fileobj && fileobj.name) {
            name = fileobj.name
        }
        let isUper = false
        let wapp_user = appAdapter().getStore().getWappUser()
        if (wapp_user && wapp_user.myInviteCode) {
            isUper = wapp_user.isUper == 0 ? false : true
        }
        // console.log('++++', this.state.selectTag)
        // console.log('......', classlist)
        return (
            <div>
                <Breadcrumb style={{ margin: '0 0 15px' }}>
                    <Breadcrumb.Item>上传助手</Breadcrumb.Item>
                    <Breadcrumb.Item>上传视频</Breadcrumb.Item>
                </Breadcrumb>
                <Form className="uploadbox" {...layout} ref={this.formRef} name="control-ref" onFinish={this.onFinish} >
                    <Form.Item name="file" label="文件" rules={[{ required: false, message: '请选择上传的文件!' }]}>
                        <div>
                            <Row>
                                <Col flex="auto" className="file-name">
                                    <span>{name}</span>
                                </Col>
                                {!isEdit && <Col className="head-fight">
                                    <Upload {...this.propsobj}>
                                        <Button style={{ background: "#EBEBEB", color: "#333333" }}>修改文件</Button>
                                    </Upload>
                                </Col>}
                            </Row>
                        </div>
                    </Form.Item>
                    <Form.Item name='movName' label="标题" rules={[{ required: true, message: '请输入标题!' }]}>
                        <Input placeholder="请输入视频标题" maxLength="30" autoComplete="off" />
                    </Form.Item>
                    <Form.Item name="movDesc" label="视频简介" rules={[{ required: true, message: '请输入视频简介!' }]}>
                        <Input.TextArea placeholder="请输入简介,最多输入120个字符" maxLength="120" className="videoDesc" />
                    </Form.Item>
                    <Form.Item name="tagids" label="分类" rules={[{ required: true, message: '请选择分类!', }]}>
                        {

                            classlist.length == 0 ?
                                // <Input placeholder="请选择分类" key='1' onClick={() => this.openClassModel()} /> :
                                <div className="placeholderTagBox" onClick={() => this.openClassModel()}>请选择分类</div> :
                                <div className="TagBox" onClick={() => this.openClassModel()}>
                                    {
                                        classlist.map((item, index) => {
                                            return <Tag className="tagitem" key={index} value={item} visible closable onClose={() => this.handleClassClose(item)}>{item.clsName}</Tag>
                                        })
                                    }
                                </div>
                        }
                    </Form.Item>
                    <Form.Item name="movCls" label="标签" rules={[{ required: true, message: '请选择标签!', }]}>
                        {

                            this.state.selectTag.length == 0 ?
                                <div className="placeholderTagBox" onClick={() => this.openModel()}>请选择标签</div> :
                                <div className="TagBox" onClick={() => this.openModel()}>
                                    {

                                        this.state.selectTag.map((item, index) => {

                                            return <Tag className="tagitem" key={index} value={item} visible closable onClose={() => this.handleClose(item)}>{item.name}</Tag>
                                        })
                                    }
                                </div>
                        }
                    </Form.Item>
                    <Form.Item name="localId" label="地区" rules={[{ required: true, message: '请选择地区!', }]}>
                        <Select
                            placeholder="请选择地区"
                            allowClear
                        >
                            {
                                this.state.selectAreaList.map((item, index) => {
                                    return <Option key={index} value={item.id}>{item.localName}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    {isUper ? <Form.Item name="movPackageId" label="金额">
                        <Select
                            placeholder="请选择套餐金额"
                            allowClear
                        >
                            {
                                this.state.charges.map((item, index) => {
                                    return <Option key={index} value={item.id}>{item.packageName}</Option>
                                })
                            }
                        </Select>
                    </Form.Item> : ''}
                    <Form.Item name="coverId" label="视频封面">

                        <div className={!imageUrl ? 'uploadImg_uploadBtn' : 'uploadImg_uploadBtn displayNone'}>
                            <input className='uploadImg_uploadBtn1' type='file' accept="image/*" id='fileimg' onChange={this.uploadRequest.bind(this)}></input>
                            选择封面
                        </div>
                        <img className={imageUrl ? 'uploadImg_img' : 'uploadImg_img displayNone'} src={imageUrl}></img>

                    </Form.Item>
                    <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 3 }}>
                        <Row className="button-box">
                            <Col span={8}>
                                <Button type="primary" size="large" className="bt1" htmlType="submit" onClick={() => this.backclick()}>
                                    返回
                            </Button>
                            </Col>
                            <Col span={8}></Col>
                            <Col span={8}>
                                <Button type="primary" size="large" className='bt2' htmlType="submit">
                                    上传视频
                            </Button>
                            </Col>
                        </Row>
                    </Form.Item>
                </Form>
                {/*标签选择弹框*/}
                <Modal
                    title="选择标签"
                    centered
                    visible={this.state.visible}
                    onOk={() => this.addSubmit(false)}
                    okText='确定'
                    cancelText='取消'
                    onCancel={() => this.setVisible(false)}
                    width={800}
                    className="ClsBox"
                >
                    <div className="Cls-Box">
                        {
                            this.state.requestAllTag.map((item, index) => {
                                return <div className="mb10" key={index}>
                                    <div className="title">{item.pname}</div>
                                    <Row className="classfiybox" gutter={[16, 12]}>
                                        {
                                            item.subclass.map((chirld, i) => {
                                                return <Col key={i} className="classfiyitem">
                                                    <div onClick={() => this.clickCls(chirld)}>
                                                        {this.renderImg(chirld)}

                                                        <span className="text"> {chirld.name}</span>
                                                    </div>
                                                </Col>
                                            })
                                        }
                                    </Row>
                                </div>
                            })
                        }
                    </div>
                </Modal>
                {/*分类选择弹框*/}
                <Modal
                    title="选择分类"
                    centered
                    visible={this.state.classvisible}
                    onOk={this.addClassSubmit.bind(this)}
                    okText='确定'
                    cancelText='取消'
                    onCancel={this.setVisible.bind(this)}
                    width={800}
                    className="ClsBox"
                >
                    <div className="Cls-Box">
                        <div className="searchClass">
                            <Search
                                placeholder="请输入搜索的分类"
                                allowClear
                                // enterButton="搜索"
                                size="large"
                                onSearch={this.onSearch}
                            />
                        </div>
                        <div className="mb20" >
                            <Row className="classfiybox" gutter={[16, 12]}>
                                {
                                    this.state.reqeustCls.map((chirld, i) => {
                                        return <Col key={i} className="classfiyitem">
                                            <div onClick={this.clickClassCls.bind(this, chirld)}>
                                                {this.renderClassImg(chirld)}
                                                <span className="text"> {chirld.clsName}</span>
                                            </div>
                                        </Col>
                                    })
                                }
                            </Row>
                        </div>

                    </div>
                </Modal>

            </div>
        )
    }
}
