/* eslint-disable import/first */
import {appAdapter} from './../app/appadaper'
import CPCONFIG from './../app/appconfig'
import { copy } from './utils';
 import crypto from 'crypto'
 //设置公共参数
 export default function makeCommonHeader(params, method, path) {
    // 把传进来的参数 进行拼接 并进行排序和签名
    var header = {} // 需要签名

    let signHeader = {};

    if (header == undefined) {
        header = {};
    }
    signHeader = copy(params);

    var authKey = 'feb48d9bffffebb322a773d8f213eebm';
    header['X-Auth-Key'] = authKey;
    signHeader['X-Auth-Key'] = authKey;

    let timestamp = new Date().getTime();
    // 时间戳
    header['X-Auth-TimeStamp'] = timestamp;
    signHeader['X-Auth-TimeStamp'] = timestamp;


    let range = 10000 - 1;
    let rand = Math.random();
    let random=  1 + Math.round(rand * range);
    // 随机数
    header['X-Auth-Nonce'] = random;
    signHeader['X-Auth-Nonce'] = random;
    // 版本号
    var release = '311';
    let cfg = new CPCONFIG();
    // release = cfg.getRelease(); // 版本号
    header['release'] = '311';
    signHeader['release'] = release;

    // 包编号
    var pkg = 'dd0001';
    // var pkg = 'cuke0347';
    header['pkg'] = pkg;
    signHeader['pkg'] = pkg;
    // 终端
    var terminal = '4';
    header['terminal'] = terminal;
    signHeader['terminal'] = terminal;

    
    // let seq = cfg.getIdfa();
    let seq='8f966d17ae1749699c124e1f1ca89662C30mC30mCJ8sC3G'

    header['Seq'] = seq;
    signHeader['Seq'] = seq;
    let auth = appAdapter().getStore().getAuthorization();
    // let auth = '03bd6560d2fbd5fe999199aab02949643b56bec90d4674cb7c9fd54a83f5b43ef6'
    if (auth != null && auth.length >0) {
        // 客户端授权key
        header['Authorization'] = auth;
    }
    else {
        header['Authorization'] = '';
    }

    signHeader['Authorization'] = header['Authorization'];
    var signString = '';

    if (method == 'POST') {
        signString = signString + method+ path;
    }
    else if (method == 'GET') {
        signString = signString + method + path;
    }
    else {
        signString = signString + method + path;
    }


    signString = signString + '?'
    //GET/startpage/combine?Authorization=&Seq=c2816a8faea149b587fa119bbab3a1dc&X-Auth-Key=368480924a6c78e2e8681551a7cf4c21&X-Auth-Nonce=2370&X-Auth-TimeStamp=1569067958000&pkg=cukepay0001&release=110&terminal=1
    var keys = Object.keys(signHeader);
    let sortKeys = keys.sort()
    var sign = ''
    var index = 0;
    for (var key of sortKeys) {
        var value = signHeader[key];

        if (value == undefined) {
            value = '';
        }

        if (index == sortKeys.length - 1) {

            signString = signString + key + '=' + value;
        }
        else {
            signString = signString + key + '=' + value + '&'
        }
        index ++;
    }
    var appSecret = 'dingding@majiaSecret#12021#0811';
    var sign = crypto.createHmac('sha1', appSecret).update(signString).digest().toString('base64');
    sign = encodeURIComponent(sign);
    header['X-Auth-Sign'] = sign;

    // header['X-Auth-Sign'] = 'RyP7DqDFohCG47YtXzaOXS03EFo%3D'
    header['Seq'] = seq;
    seq = cfg.getIdfa();
    return header;
}
 //设置公共参数
//  export function makeTaitanCommonHeader(params, url) {
//     var header = {} // 需要签名
//     if (header == undefined) {
//         header = {};
//     }
//     header['Content-Type'] = 'application/x-www-form-urlencoded';
//     header['Access-Control-Allow-Headers'] = '*';
//     header['Access-Control-Allow-Origin'] = '*';
//     var authKey = window.taitanKey;
//     header['X-Auth-Key'] = authKey;

//     // 时间戳
//     let timestamp = new Date().getTime();
//     header['X-Auth-TimeStamp'] = timestamp;

//     //随机产生
//     let rangeStr = '';
//     for (var i=0; i<32; i++){
//         var number = Math.floor(Math.random() * 36);
//         if (number < 10){
//             var figure = Math.floor(Math.random() * 10);
//             rangeStr = rangeStr + figure
//         }else{
//             var figure = Math.floor(Math.random() * 26) + 97;
//             var res = String.fromCharCode(figure)
//             rangeStr = rangeStr + res
//         }
//     }
//     header['X-Auth-Nonce'] = rangeStr;

//     var url = window.taitanURL.toString();
//     var arrUrl = url.split("//");
//     var start = arrUrl[1].indexOf("/");
//     var relUrl = arrUrl[1].substring(start);//stop省略，截取从start开始到结尾的所有字符
//     if(relUrl.indexOf("?") != -1){
//         relUrl = relUrl.split("?")[0];
//     }
//     let path = relUrl;
//     let sign = "POST"+path+"?Authorization=&Seq=&Version=&X-Auth-Key="+authKey+"&X-Auth-Nonce="+rangeStr+"&X-Auth-TimeStamp="+timestamp+"&userId=45"+"&userType=3"
//     var signString = crypto.createHmac('sha1', window.taitanSecret).update(sign).digest().toString('base64');
//     header['X-Auth-Sign'] = encodeURIComponent(signString);
//     // header['X-Auth-Sign'] = 'RyP7DqDFohCG47YtXzaOXS03EFo%3D'
//     return header;
// }
　　

    

