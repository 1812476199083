import React, { Component } from 'react';
import videojs from "video.js/dist/video.js";
import "video.js/dist/video-js.css";
import './index.scss'
 
let CPPLAYER_INSTANCE_ID = 100;
export default class AlertVideo extends Component {
    constructor() {
        super(...arguments);
        
        this.videoUrl = ""; // 播放地址
        this.state = {};
        this.videoOptions = {}; //播放器参数
        this.videoCoverUrl = ""; // 视频封面
        this.currentPlayTime = 0;
        this._videojs = null; //  底层的播放对象
        this._id = CPPLAYER_INSTANCE_ID + 1;
        this._currentTime = 0;
        this._object = undefined;
        this._firstPlayHandler = undefined; //  第一次播放的时候处理
        this._playTimeUpdateHandler = undefined; //播放时间更新的时候处理
        CPPLAYER_INSTANCE_ID++;
    }


    componentWillUnmount() {
        this._videojs.off('firstplay');
        this._videojs.off('timeupdate');
        this._videojs && this._videojs.dispose();
        this._videojs = undefined;
    }

    //  设置播放选项
    setupVideoOptions() { }

    // 设置播放地址 
    // url 播放地址
    // time 指定的时间段播放
    // obj 设置给播放器的对象
    /* setVideoUrl(url,videoCoverUrl, time = 0, obj = undefined) {
        this.videoUrl = url;
        this.videoCoverUrl = videoCoverUrl;
        this.currentPlayTime = time;
        this.obj = obj;
        this.internal_initVideo();

    } */
    setVideoUrl() {
        let {item}=this.props
        console.log('item-------------------')
        console.log(item)
        this.videoCoverUrl =  item.image
        this.videoUrl = item.videoUrl;
        console.log(item.videoUrl)
        this.currentPlayTime = 0;
        // this.obj = obj;
        this.internal_initVideo();

    }
    //  清除掉播放数据
    clearVideo() {
        this._videojs.dispose();
        this._videojs = undefined;
    }

    // 暂停播放
    pauseVideo() {
        this._videojs.pause();
    }

    // 隐藏播放并暂停
    hiddenPlay() {
        this.pauseVideo();
    }

    // 重新播放，不释放资源
    resetPlay() {
        this._videojs.currentTime(0);
        this._videojs.play();
    }

    currentTime() {
        return this._videojs.currentTime();
    }

    setupFirstPlayerHander(fn) {
        this._firstPlayHandler = fn;
    }

    setupPlayTimeUpdateHandler(fn) {
        this._playTimeUpdateHandler = fn;
    }

    setupPlayFrame() {}


    internal_initVideo() {
        let videoOptions = {
            autoplay: true, // 如果true,浏览器准备好时开始回放。
            controls: true, // 是否显示控制条
            preload: "auto", // 视频预加载
            loop: false, // 导致视频一结束就重新开始。
            language: "zh-CN",
            aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
            fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
            poster: this.videoCoverUrl, // 你的封面地址
            sources: [
                {
                    type: "application/x-mpegURL",
                    src: this.videoUrl
                    //src: "https://35.220.167.29:18062/cukefree/4058/CF685A99AC3052FD362D33ECAF19134D/CF685A99AC3052FD362D33ECAF19134D_9bf66cf92e6043ce9b0ba02de80088b5.mp4/index.m3u8?browToken=emptyBrowToken"
                }
            ],
            html5: {
                hls: {
                    withCredentials: false
                }
            },
            nativeVideoTracks: false, //可以设置为false禁用本机视频轨道支持
            notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
            controlBar: {}
        };
        this.videoOptions = videoOptions;

        let videoId = `webPlayer${this._id}`;

        // console.log(`webPlayer${this._id}`);
        
        this._videojs = videojs(videoId, videoOptions);
        let $loading = document.getElementsByClassName("video-js")[0];
        $loading.classList.add("vjs-seeking", "vjs-waiting");
        let $button = document.getElementsByClassName('vjs-big-play-button')[0]

        let that = this;
        this._videojs.on("firstplay", () => {
            if (that._firstPlayHandler) {
                that._firstPlayHandler();
            }

            let player = videojs(videoId)
            player.controlBar.show()

        });

        that._videojs.on('play',()=>{
            $button.style.display = "none"
        });

        this._videojs.on("timeupdate", function () {
            if (that._playTimeUpdateHandler) {
                this._playTimeUpdateHandler();
            }
        });
    }
    componentDidMount(){
        this.setVideoUrl()
    }
   render() {
      return (
        <div id={`CPWebPlayer${CPPLAYER_INSTANCE_ID}`} className="CPWebPlayer">
            <video id={`webPlayer${this._id}`} x5-video-player-type='h5' 
                x5-video-orientation='h5'
             x5-video-player-fullscreen="false" className="video-js vjs-big-play-centered vjs-default-skin" playsInline  webkit-playsinline="true"></video>
        </div>
      );
   }
}