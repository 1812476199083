import {getServerCfg} from "./appdomain"
import {getStorageSync} from './appstorage'
function imgUrlComPatible(realmName,url){
    // 传入域名realmName   数据中的影片地址url
    // 截取  域名realmName最后一位  和  影片地址url的第一位
    let realmNameLast = realmName.charAt(realmName.length-1)
    let urlFirst = url.charAt(0)
    if(realmNameLast==='/'&&urlFirst==='/'){
        // 如果 域名realmName最后一位  和  影片地址url的第一位  都是‘/’ 及去掉影片地址url的第一位
        url = url.substring(1)
    }else if(realmNameLast!=='/'&&urlFirst!=='/'){
        // 如果 域名realmName最后一位  和  影片地址url的第一位  都不是‘/’ 及添加域名realmName最后一位为‘/’
        realmName = realmName+'/'
    }
    let realmUrl = realmName + url
    // 截取 /_eny/
    realmUrl = realmUrl.replace(/_eny/,'')
    return realmUrl
}

export function videoCover(coverName) {
    if (coverName == undefined) {
        return coverName;
    }

    let BucketData = getStorageSync('bucketData')
    let bucketKind = 1
    for (let bucketitem in BucketData) {
        let bucketValue = BucketData[bucketitem]
        if (coverName.indexOf(bucketitem) >= 0) {//包含
            if (bucketValue == 1) {
                bucketKind = 1
            } else {
                bucketKind = 2
            }
        }
    }
    if (bucketKind == 1) {
        return imgUrlComPatible(getServerCfg().coverUrl(), coverName);
    } else {
        return imgUrlComPatible(getServerCfg().coverUrl_new(), coverName);
    }
}

// 图片上传相关
export function imageUploadUrl() {
    return getServerCfg().imageUpload()
}

export function CPVideoUrlV2(videoName) {

    return CPVideoUrlV3(videoName);
}
function isVaildVideoUrl(videoName) {

    if ((/^http?:\/\//.test(videoName)) || (/^https?:\/\//.test(videoName))) {
        return true;
    }
    else {
        return false;
    }
}
export function CPVideoUrlV3(videoName) {
    let url = '';
    if (isVaildVideoUrl(videoName)) {
        url = videoName;
    }
    else {

        url = getServerCfg().videoUrlV3();
        url = imgUrlComPatible(url, videoName);
    }

    if (url != undefined && url.indexOf('/ali.m3u8') > 0) {
        url = '';
    }

    return url;
}
export function CPVideoUrl(videoName) {

    let url = '';
    // 如果带有http 或 https 就直接返回
    if ((/^http?:\/\//.test(videoName)) || (/^https?:\/\//.test(videoName))) {
       url =  videoName;
    } else {
        url = getServerCfg().videoUrl();
        url = imgUrlComPatible(url, videoName);
    }

    if (url !== undefined && url.indexOf('/ali.m3u8') >0) {
        url = '';
    }
    // console.log('获取的播放链接:'+url);
    return url;
  
}
